import {
  type Product,
  type OrderRequestLineItem,
  type SpendCategory,
  SpendCategoryStatusEnum,
  SpendCategoryFieldStatusEnum
} from '@amici/myamici-api-client'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Fragment, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { BsExclamationTriangle, BsPencil, BsXLg } from 'react-icons/bs'
import { SmilesSvgRenderer } from 'react-ocl/minimal.js'
import { Spinner } from 'react-bootstrap'
import useSpendCategoriesTree from '../../spend-categories/hooks/useSpendCategoriesTree'
import useSpendCategories from '../../spend-categories/hooks/useSpendCategories'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import MaCheckbox, {
  type CheckedState
} from '../../common/components/MaCheckbox'
import FallbackImage from '../../common/components/FallbackImage'
import MaActionIcon from '../../common/components/MaActionIcon'
import ProductStock from '../../purchasing/components/ProductStock'
import { useUpdatingLineItemsState } from './OrderRequestUpdatingLineItemsProvider'
import styles from '../assets/scss/OrderRequestLineItemCard.module.scss'
import MaTooltip from '../../common/components/MaTooltip'

interface OrderRequestLineItemCardBaseProps {
  lineItem: OrderRequestLineItem
  selected: boolean
  selectable: boolean
  canChange?: boolean
  onEdit: (lineItem: OrderRequestLineItem) => void
  onRemove: (lineItem: OrderRequestLineItem) => void
  onSelectedChange: (
    lineItem: OrderRequestLineItem,
    checked: CheckedState
  ) => void
}

export interface OrderRequestLineItemCardProps
  extends OrderRequestLineItemCardBaseProps {
  compactView: boolean
}

function ProductSummary ({
  product
}: Readonly<{ product: Product }>): ReactElement {
  const { t } = useTranslation()

  const packSize = product.pack_size ?? 0
  const packSizeValue =
    ((product.no_of_units ?? 0) > 1 ? `${product.no_of_units} x ` : '') +
    packSize.toString()
  const packSizeUnit: string = product.pack_size_unit ?? ''

  return (
    <table>
      <tbody>
        <tr>
          <th>{t('order_request.item.product')}</th>
          <td>
            <Link to={`/purchasing/products/${product.id}`}>
              {product.description}
            </Link>
          </td>
        </tr>
        <tr>
          <th>{t('common.product.part_number')}</th>
          <td>{product.part_number}</td>
        </tr>
        <tr>
          <th>{t('common.product.pack_size')}</th>
          <td>
            {t('common.product.pack_size_value', {
              packSizeValue,
              packSizeUnit: t([`units.${packSizeUnit}`, packSizeUnit], {
                count: +packSize
              })
            })}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

function Quantity ({ quantity }: Readonly<{ quantity: number }>): ReactElement {
  const { t } = useTranslation()

  return (
    <table className={styles.quantity}>
      <tbody>
        <tr>
          <th>{t('common.quantity')}</th>
          <td>{quantity}</td>
        </tr>
      </tbody>
    </table>
  )
}

function CostSummary ({
  lineItem,
  compactView
}: Readonly<{
  lineItem: OrderRequestLineItem
  compactView?: boolean
}>): ReactElement {
  const { t } = useTranslation()

  const currency = lineItem.line_item.currency ?? 'GBP'
  const quantity = lineItem.line_item.quantity
  const price = lineItem.line_item.price ?? 0
  const subtotal = price * quantity

  return (
    <div className={styles['cost-summary']}>
      <table>
        <tbody>
          {compactView && (
            <tr>
              <th>{t('common.quantity')}</th>
              <td>{quantity}</td>
            </tr>
          )}
          <tr>
            <th>{t('common.label.price')}</th>
            <td>
              {t('common.price', {
                price,
                currency
              })}
            </td>
          </tr>
          <tr>
            <th>{t('common.subtotal')}</th>
            <td>
              {t('common.price', {
                price: subtotal,
                currency
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

function SpendCategoryName ({
  spendCategory,
  spendCategories
}: Readonly<{
  spendCategory: SpendCategory | undefined
  spendCategories: SpendCategory[]
}>): ReactElement {
  const { t } = useTranslation()
  const { getPath } = useSpendCategoriesTree(spendCategories)

  const path = getPath(spendCategory?.id ?? 0).filter(id => !!id)
  const formattedValue = `${spendCategory?.name} (${spendCategory?.code})`
  const formattedPath =
    path
      .filter(id => id !== spendCategory?.id)
      .map(
        id =>
          spendCategories.find(spendCategory => spendCategory.id === id)?.name
      )
      .join(' / ') + `${path.length > 1 ? ' / ' : ''}${formattedValue}`

  const missing = !spendCategory
  const inactive = spendCategory?.status === SpendCategoryStatusEnum.INACTIVE
  const warningText = t('order_request.updates_required.spend_category', {
    context: missing ? 'missing' : 'inactive'
  })

  if (missing || inactive) {
    return (
      <MaTooltip text={warningText} trigger={['hover', 'focus']}>
        <BsExclamationTriangle
          className={styles['warning-icon']}
          aria-label={warningText}
        />
      </MaTooltip>
    )
  }

  return <strong title={formattedPath}>{formattedValue}</strong>
}

function SpendCategories ({
  lineItem
}: Readonly<{
  lineItem: OrderRequestLineItem
}>): ReactElement | null {
  const { t } = useTranslation()
  const { data: spendCategoryFields } = useSpendCategories(false)
  const itemSpendCategories = lineItem.line_item.spend_categories
  const activeSpendCategoryFields =
    spendCategoryFields?.filter(
      field => field.status === SpendCategoryFieldStatusEnum.ACTIVE
    ) ?? []
  const selectedSpendCategoryFields =
    spendCategoryFields?.filter(field =>
      itemSpendCategories
        ?.map(spendCategory => spendCategory.field_id)
        .includes(field.id)
    ) ?? []
  const activeOrSelectedSpendCategoryFields = [
    ...new Set([...activeSpendCategoryFields, ...selectedSpendCategoryFields])
  ]

  if (
    activeSpendCategoryFields.length === 0 &&
    selectedSpendCategoryFields.length === 0
  ) {
    return null
  }

  return (
    <table
      className={styles['spend-categories']}
      aria-label={'spend-categories'}
    >
      <tbody>
        <tr>
          <th>{t('order_request.spend.spend_categories')}</th>

          <td>
            {activeOrSelectedSpendCategoryFields
              ?.sort((a, b) => a.id.localeCompare(b.id))
              .map((spendCategoryField, index) => (
                <Fragment key={spendCategoryField.id}>
                  <SpendCategoryName
                    spendCategory={itemSpendCategories?.find(
                      spendCategory =>
                        spendCategory.field_id === spendCategoryField.id
                    )}
                    spendCategories={spendCategoryField.spend_categories}
                  />
                  {index < activeOrSelectedSpendCategoryFields?.length - 1 && (
                    <span> &gt; </span>
                  )}
                </Fragment>
              ))}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

function CompactCard ({
  lineItem,
  selected,
  selectable,
  canChange,
  onEdit,
  onRemove,
  onSelectedChange
}: Readonly<OrderRequestLineItemCardBaseProps>): ReactElement {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles['line-item-card'], styles.compact)}>
      <div className={styles['product-summary-row']}>
        {selectable && (
          <MaCheckbox
            aria-label={lineItem.line_item.product.part_number ?? ''}
            checked={selected}
            onCheckedChange={checked => {
              onSelectedChange(lineItem, checked)
            }}
          />
        )}

        <ProductSummary product={lineItem.line_item.product} />

        {canChange && (
          <MaActionMenu aria-label={t('order_request.line_item.action.menu')}>
            <MaActionMenuItem
              onClick={() => {
                onEdit(lineItem)
              }}
            >
              {t('order_request.action.line_item.edit')}
            </MaActionMenuItem>
            <MaActionMenuItem
              onClick={() => {
                onRemove(lineItem)
              }}
            >
              {t('order_request.action.line_item.remove')}
            </MaActionMenuItem>
          </MaActionMenu>
        )}
      </div>

      <CostSummary lineItem={lineItem} compactView={true} />

      <SpendCategories lineItem={lineItem} />

      <ProductStock
        className={styles['stock-info']}
        productId={lineItem.line_item.product.id}
        isSelfServe={!!lineItem.line_item.product.is_self_serve}
        status={lineItem.line_item.product.status ?? ''}
      />
    </div>
  )
}

function FullCard ({
  lineItem,
  selected,
  selectable,
  canChange,
  onEdit,
  onRemove,
  onSelectedChange
}: Readonly<OrderRequestLineItemCardBaseProps>): ReactElement {
  const { t } = useTranslation()
  const product = lineItem.line_item.product
  const state = useUpdatingLineItemsState()
  const pendingEdit = !!state?.activeEditingIds.includes(lineItem.line_item.id)

  return (
    <div className={styles['line-item-card']}>
      <div className={styles['product-summary-row']}>
        {selectable && (
          <MaCheckbox
            aria-label={lineItem.line_item.product.part_number ?? ''}
            checked={selected}
            onCheckedChange={checked => {
              onSelectedChange(lineItem, checked)
            }}
          />
        )}

        <div className={styles['product-summary']}>
          <div className={styles['img-wrapper']}>
            {product.product_image_url && (
              <FallbackImage
                src={product.product_image_url ?? ''}
                alt={product.description ?? ''}
              />
            )}

            {!product.product_image_url && product.smiles && (
              <SmilesSvgRenderer
                width={88}
                height={88}
                smiles={product?.smiles ?? ''}
                ErrorComponent={FallbackImage}
              />
            )}
          </div>

          <ProductSummary product={lineItem.line_item.product} />
        </div>

        <Quantity quantity={lineItem.line_item.quantity} />

        <CostSummary lineItem={lineItem} />

        <div className={styles.actions}>
          {canChange && (
            <>
              {pendingEdit ? (
                <Spinner role="status" aria-busy="true" size="sm" />
              ) : (
                <MaActionIcon
                  title={t('order_request.action.line_item.edit')}
                  className={styles['action-btn']}
                  onClick={() => {
                    onEdit(lineItem)
                  }}
                >
                  <BsPencil size={16} />
                </MaActionIcon>
              )}
              <MaActionIcon
                title={t('order_request.action.line_item.remove')}
                className={styles['action-btn']}
                onClick={() => {
                  onRemove(lineItem)
                }}
              >
                <BsXLg size={16} />
              </MaActionIcon>
            </>
          )}
        </div>
      </div>

      <SpendCategories lineItem={lineItem} />

      <ProductStock
        className={styles['stock-info']}
        productId={lineItem.line_item.product.id}
        isSelfServe={!!lineItem.line_item.product.is_self_serve}
        status={lineItem.line_item.product.status ?? ''}
      />
    </div>
  )
}

function OrderRequestLineItemCard ({
  compactView,
  ...props
}: Readonly<OrderRequestLineItemCardProps>): ReactElement {
  return compactView ? <CompactCard {...props} /> : <FullCard {...props} />
}

export default OrderRequestLineItemCard
